codenteam-search-form {
    $height: 40px;

    form {
        display: flex;
        justify-content: center;
    }

    .mat-mdc-form-field-infix {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
        height: $height !important;
        min-height: $height !important;
        line-height: $height !important;
    }

    mat-form-field {
        height: $height !important;
        align-self: center;
        border-radius: 125px !important;


    }

    .mdc-notched-outline {
        display: none;
    }

    .mat-mdc-form-field-icon-prefix>.mat-icon,
    .mat-mdc-form-field-icon-suffix>.mat-icon {
        padding: 8px;
        box-sizing: content-box;
    }
}